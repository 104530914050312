import Cookies from 'universal-cookie';

import cookieOptions from './cookieOptions';
import { logOut } from './logOut';
import * as actionTypes from './actionTypes';

export const logInStart = () => ({
  type: actionTypes.LOG_IN_START,
});

export const logInSuccess = (token) => ({
  type: actionTypes.LOG_IN_SUCCESS,
  token,
});

export const logInFail = (error) => ({
  type: actionTypes.LOG_IN_FAIL,
  error,
});

export const checkAuthTimeout = () => (
  (dispatch) => {
    const cookies = new Cookies();
    const tokenExpiresAt = cookies.get('tokenExpiresAt');
    if (!tokenExpiresAt) {
      return;
    }
    const expiresAt = new Date(tokenExpiresAt);
    const now = new Date();
    if (now.getTime() >= expiresAt.getTime()) {
      dispatch(logOut());
    }
    const expiresIn = expiresAt.getTime() - now.getTime();
    setTimeout(() => {
      dispatch(checkAuthTimeout());
    }, Math.min(0x7fffffff, expiresIn));
  }
);

export const loggedIn = (token, expiresAt) => (
  (dispatch) => {
    const cookies = new Cookies();
    const options = {
      ...cookieOptions,
      expires: expiresAt,
    };
    cookies.set('token', token, options);
    cookies.set('tokenExpiresAt', expiresAt, options);
    dispatch(logInSuccess(token));
    dispatch(checkAuthTimeout());
  }
);

export const logInSetRedirectPath = (path) => ({
  type: actionTypes.LOG_IN_SET_REDIRECT_PATH,
  path,
});

export const authCheckState = () => (
  (dispatch) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    if (token) {
      dispatch(logInSuccess(token));
      dispatch(checkAuthTimeout());
    }
  }
);
