import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  error: null,
  loading: false,
};

const generateBotTokenStart = (state) => (
  updateObject(state, {
    error: null,
    loading: true,
  })
);

const generateBotTokenSuccess = (state) => (
  updateObject(state, {
    error: null,
    loading: false,
  })
);

const generateBotTokenFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false,
  })
);

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.GENERATE_BOT_TOKEN_START:
      return generateBotTokenStart(state);
    case actionTypes.GENERATE_BOT_TOKEN_SUCCESS:
      return generateBotTokenSuccess(state);
    case actionTypes.GENERATE_BOT_TOKEN_FAIL:
      return generateBotTokenFail(state, action);
    default:
      return state;
  }
};

export default reducer;
