import NavigationItem from './NavigationItem/NavigationItem';
import NavigationItemExternal from './NavigationItem/NavigationItemExternal';

import classes from './NavigationItems.module.css';

const navigationItems = (props) => (
  <ul className={classes.NavigationItems}>
    <NavigationItem link="/" exact>Home</NavigationItem>
    { props.isAuthenticated
      ? <NavigationItem link="/documentation" exact>Documentation</NavigationItem>
      : null }
    { props.isAuthenticated
      ? <NavigationItemExternal link="https://discord.com/invite/EC2Xfj2UKz">Discord</NavigationItemExternal>
      : null }
    { props.isAuthenticated
      ? <NavigationItem link="/logout">Log Out</NavigationItem>
      : <NavigationItem link="/signup">Sign Up</NavigationItem> }
    { props.isAuthenticated
      ? null
      : <NavigationItem link="/login">Log In</NavigationItem> }
  </ul>
);

export default navigationItems;
