import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  error: null,
  loading: false,
};

const logOutStart = (state) => (
  updateObject(state, {
    error: null,
    loading: true,
  })
);

const logOutSuccess = (state) => (
  updateObject(state, {
    error: null,
    loading: false,
  })
);

const logOutFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false,
  })
);

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.LOG_OUT_START:
      return logOutStart(state);
    case actionTypes.LOG_OUT_SUCCESS:
      return logOutSuccess(state);
    case actionTypes.LOG_OUT_FAIL:
      return logOutFail(state, action);
    default:
      return state;
  }
};

export default reducer;
