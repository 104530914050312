import axios from './axios_engine';
import { userDataSuccess } from './userData';
import * as actionTypes from './actionTypes';

export const generateBotTokenStart = () => ({
  type: actionTypes.GENERATE_BOT_TOKEN_START,
});

export const generateBotTokenSuccess = () => ({
  type: actionTypes.GENERATE_BOT_TOKEN_SUCCESS,
});

export const generateBotTokenFail = (error) => ({
  type: actionTypes.GENERATE_BOT_TOKEN_FAIL,
  error,
});

export const generateBotToken = (token, id) => (
  (dispatch) => {
    dispatch(generateBotTokenStart());
    const url = `/bots/${id}/token`;
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.put(url, {}, options)
      .then((response) => {
        if (response.status === 200) {
          dispatch(userDataSuccess(response.data));
          dispatch(generateBotTokenSuccess());
        } else {
          dispatch(generateBotTokenFail(response.data.detail));
        }
      })
      .catch((err) => {
        let error = err.message;
        if (err.response && err.response.data) {
          error = err.response.data.error;
        }
        dispatch(generateBotTokenFail(error));
      });
  }
);
