import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  error: null,
  loading: false,
  email: null,
  bots: null,
};

const userDataStart = (state) => (
  updateObject(state, {
    error: null,
    loading: true,
    email: null,
    bots: null,
  })
);

const userDataSuccess = (state, action) => (
  updateObject(state, {
    error: null,
    loading: false,
    email: action.email,
    bots: action.bots,
  })
);

const userDataFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false,
  })
);

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.USER_DATA_START:
      return userDataStart(state);
    case actionTypes.USER_DATA_SUCCESS:
      return userDataSuccess(state, action);
    case actionTypes.USER_DATA_FAIL:
      return userDataFail(state, action);
    default:
      return state;
  }
};

export default reducer;
