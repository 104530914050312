import { Link } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const navigationItemExternal = (props) => (
  <li className={classes.NavigationItem}>
    <Link
      to={{ pathname: props.link }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </Link>
  </li>
);

export default navigationItemExternal;
