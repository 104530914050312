import Cookies from 'universal-cookie';

import axios from './axios_web';
import cookieOptions from './cookieOptions';
import * as actionTypes from './actionTypes';

export const logOutStart = () => ({
  type: actionTypes.LOG_OUT_START,
});

export const logOutSuccess = () => ({
  type: actionTypes.LOG_OUT_SUCCESS,
});

export const logOutFail = (error) => ({
  type: actionTypes.LOG_OUT_FAIL,
  error,
});

export const authDeleteToken = () => {
  const cookies = new Cookies();
  cookies.remove('token', cookieOptions);
  cookies.remove('tokenExpiresAt', cookieOptions);
  return {
    type: actionTypes.AUTH_DELETE_TOKEN,
  };
};

export const logOut = () => (
  (dispatch) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    if (!token) return;
    dispatch(authDeleteToken());
    dispatch(logOutStart());
    const url = '/users/token';
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.delete(url, options)
      .then((response) => {
        if (response.status === 200) {
          dispatch(logOutSuccess());
        } else {
          dispatch(logOutFail(response.data.detail));
        }
      })
      .catch((err) => {
        let error = err.message;
        if (err.response && err.response.data) {
          error = err.response.data.error;
        }
        dispatch(logOutFail(error));
      });
  }
);
