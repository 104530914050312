import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  token: null,
  error: null,
  loading: false,
  logInRedirectPath: '/',
};

const logInStart = (state) => (
  updateObject(state, {
    error: null,
    loading: true,
  })
);

const logInSuccess = (state, action) => (
  updateObject(state, {
    token: action.token,
    error: null,
    loading: false,
  })
);

const logInFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false,
  })
);

const logInSetRedirectPath = (state, action) => (
  updateObject(state, {
    logInRedirectPath: action.path,
  })
);

const authDeleteToken = (state) => (
  updateObject(state, {
    token: null,
  })
);

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.LOG_IN_START:
      return logInStart(state);
    case actionTypes.LOG_IN_SUCCESS:
      return logInSuccess(state, action);
    case actionTypes.LOG_IN_FAIL:
      return logInFail(state, action);
    case actionTypes.LOG_IN_SET_REDIRECT_PATH:
      return logInSetRedirectPath(state, action);
    case actionTypes.AUTH_DELETE_TOKEN:
      return authDeleteToken(state);
    default:
      return state;
  }
};

export default reducer;
