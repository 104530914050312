import axios from './axios_web';
import * as actionTypes from './actionTypes';

export const userDataStart = () => ({
  type: actionTypes.USER_DATA_START,
});

export const userDataSuccess = (data) => ({
  type: actionTypes.USER_DATA_SUCCESS,
  email: data.email,
  bots: data.bots,
});

export const userDataFail = (error) => ({
  type: actionTypes.USER_DATA_FAIL,
  error,
});

export const userData = (token) => (
  (dispatch) => {
    dispatch(userDataStart());
    const url = '/users';
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.get(url, options)
      .then((response) => {
        if (response.status === 200) {
          dispatch(userDataSuccess(response.data));
        } else {
          dispatch(userDataFail(response.data.detail));
        }
      })
      .catch((err) => {
        let error = err.message;
        if (err.response && err.response.data) {
          error = err.response.data.error;
        }
        dispatch(userDataFail(error));
      });
  }
);
