import { Component } from 'react';
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/Layout/Layout';
import * as actions from './store/actions/index';

const asyncSignUp = asyncComponent(() => (
  import('./containers/Auth/SignUp/SignUp')
));

const asyncSignUpRegister = asyncComponent(() => (
  import('./containers/Auth/SignUpRegister/SignUpRegister')
));

const asyncLogIn = asyncComponent(() => (
  import('./containers/Auth/LogIn/LogIn')
));

const asyncLogInToken = asyncComponent(() => (
  import('./containers/Auth/LogInToken/LogInToken')
));

const asyncLogOut = asyncComponent(() => (
  import('./containers/Auth/LogOut/LogOut')
));

const asyncHome = asyncComponent(() => (
  import('./containers/Home/Home')
));

const asyncLeaderboard = asyncComponent(() => (
  import('./containers/Home/ScoresLeaderboard')
));

const asyncView = asyncComponent(() => (
  import('./containers/View/View')
));

const asyncDocumentation = asyncComponent(() => (
  import('./containers/Documentation/Contents')
));

class App extends Component {
  componentDidMount() {
    const { onTryToken } = this.props;
    onTryToken();
  }

  render() {
    return (
      <div>
        <Layout>
          <Switch>
            <Route path="/" exact component={asyncHome} />
            <Route path="/leaderboard" exact component={asyncLeaderboard} />
            <Route path="/view/:id" exact component={asyncView} />
            <Route path="/login" exact component={asyncLogIn} />
            <Route path="/login/:token" exact component={asyncLogInToken} />
            <Route path="/logout" exact component={asyncLogOut} />
            <Route path="/documentation" exact component={asyncDocumentation} />
            <Route path="/confirm/:token" exact component={asyncSignUpRegister} />
            <Route path="/signup" exact component={asyncSignUp} />
            <Redirect to="/" />
          </Switch>
        </Layout>
      </div>
    );
  }
}
App.propTypes = {
  onTryToken: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onTryToken: () => dispatch(actions.authCheckState()),
});

export default withRouter(connect(null, mapDispatchToProps)(App));
