import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_WEB_BACKEND_BASE_URL,
  validateStatus: (status) => (
    (status >= 200 && status < 300) || status === 400
  ),
});

export default instance;
