import axios from './axios_web';
import * as actionTypes from './actionTypes';

export const leaderboardScoresStart = () => ({
  type: actionTypes.LEADERBOARD_SCORES_START,
});

export const leaderboardScoresSuccess = (scores) => ({
  type: actionTypes.LEADERBOARD_SCORES_SUCCESS,
  scores,
});

export const leaderboardScoresFail = (error) => ({
  type: actionTypes.LEADERBOARD_SCORES_FAIL,
  error,
});

export const leaderboardScores = (token) => (
  (dispatch) => {
    dispatch(leaderboardScoresStart());
    const url = '/leaderboard';
    let options = {};
    if (token) {
      options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    axios.get(url, options)
      .then((response) => {
        dispatch(leaderboardScoresSuccess(response.data));
      })
      .catch((err) => {
        let error = err.message;
        if (err.response && err.response.data) {
          error = err.response.data.error;
        }
        dispatch(leaderboardScoresFail(error));
      });
  }
);
