export const updateObject = (oldObject, updatedValues) => (
  {
    ...oldObject,
    ...updatedValues,
  }
);

export const checkValidity = (value, rules) => {
  let isValid = true;
  const valueTrimmed = value.trim();
  const hasValue = valueTrimmed !== '';
  if (rules.required) {
    isValid = hasValue && isValid;
  }
  const { length } = valueTrimmed;
  if (rules.minLength) {
    isValid = hasValue && isValid && length >= rules.minLength;
  }
  if (rules.maxLength) {
    isValid = hasValue && isValid && length <= rules.maxLength;
  }
  if (rules.isEmail) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = hasValue && isValid && pattern.test(valueTrimmed);
  }
  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = hasValue && isValid && pattern.test(valueTrimmed);
  }
  return isValid;
};
