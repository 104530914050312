import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import thunk from 'redux-thunk';

// import reportWebVitals from './reportWebVitals';
// import registerServiceWorker from './registerServiceWorker';
import App from './App';
import signUpReducer from './store/reducers/signUp';
import authReducer from './store/reducers/logIn';
import logOutReducer from './store/reducers/logOut';
import userReducer from './store/reducers/userData';
import trainingsListReducer from './store/reducers/trainingsList';
import trainingsRegisterReducer from './store/reducers/trainingsRegister';
import generateBotTokenReducer from './store/reducers/generateBotToken';
import leaderboardScoresReducer from './store/reducers/leaderboardScores';

import './index.css';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
  signUp: signUpReducer,
  auth: authReducer,
  logOut: logOutReducer,
  user: userReducer,
  trainings: trainingsListReducer,
  trainingsRegister: trainingsRegisterReducer,
  generateBotToken: generateBotTokenReducer,
  leaderboardScores: leaderboardScoresReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk),
  ),
);

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// eslint-disable-next-line jest/require-hook
ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: reportWebVitals(console.log);

// TODO: registerServiceWorker();
