import burgerLogo from '../../assets/images/icons8-bot-100.png';

import classes from './Logo.module.css';

const logo = () => (
  <div className={classes.Logo}>
    <img src={burgerLogo} alt="Bot" />
  </div>
);

export default logo;
