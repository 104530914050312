import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  error: null,
  loading: false,
  isOpen: false,
  requiresCode: false,
  email: null,
  token: null,
};

const signUpStart = (state) => (
  updateObject(state, {
    error: null,
    loading: true,
    isOpen: false,
    requiresCode: false,
    email: null,
    token: null,
  })
);

const signUpSuccess = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false,
    isOpen: action.isOpen,
    requiresCode: action.requiresCode,
    email: action.email,
    token: action.token,
  })
);

const signUpFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false,
  })
);

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_START:
      return signUpStart(state);
    case actionTypes.SIGN_UP_SUCCESS:
      return signUpSuccess(state, action);
    case actionTypes.SIGN_UP_FAIL:
      return signUpFail(state, action);
    default:
      return state;
  }
};

export default reducer;
