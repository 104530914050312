import axios from './axios_web';
import * as actionTypes from './actionTypes';

export const trainingsListStart = () => ({
  type: actionTypes.TRAININGS_LIST_START,
});

export const trainingsListSuccess = (trainings) => ({
  type: actionTypes.TRAININGS_LIST_SUCCESS,
  trainings,
});

export const trainingsListFail = (error) => ({
  type: actionTypes.TRAININGS_LIST_FAIL,
  error,
});

export const trainingsList = () => (
  (dispatch) => {
    dispatch(trainingsListStart());
    const url = '/trainings';
    axios.get(url)
      .then((response) => {
        dispatch(trainingsListSuccess(response.data));
      })
      .catch((err) => {
        let error = err.message;
        if (err.response && err.response.data) {
          error = err.response.data.error;
        }
        dispatch(trainingsListFail(error));
      });
  }
);
