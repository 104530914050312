import axios from './axios_engine';
import { userDataSuccess } from './userData';
import * as actionTypes from './actionTypes';

export const trainingsRegisterStart = () => ({
  type: actionTypes.TRAININGS_REGISTER_START,
});

export const trainingsRegisterSuccess = () => ({
  type: actionTypes.TRAININGS_REGISTER_SUCCESS,
});

export const trainingsRegisterFail = (error) => ({
  type: actionTypes.TRAININGS_REGISTER_FAIL,
  error,
});

export const trainingsRegister = (token, id) => (
  (dispatch) => {
    dispatch(trainingsRegisterStart());
    const url = `/trainings/${id}`;
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.put(url, {}, options)
      .then((response) => {
        dispatch(userDataSuccess(response.data));
        dispatch(trainingsRegisterSuccess());
      })
      .catch((err) => {
        let error = err.message;
        if (err.response && err.response.data) {
          error = err.response.data.error;
        }
        dispatch(trainingsRegisterFail(error));
      });
  }
);
