import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  error: null,
  loading: false,
  trainings: [],
};

const trainingsListStart = (state) => (
  updateObject(state, {
    error: null,
    loading: true,
    trainings: [],
  })
);

const trainingsListSuccess = (state, action) => (
  updateObject(state, {
    error: null,
    loading: false,
    trainings: action.trainings,
  })
);

const trainingsListFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false,
  })
);

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.TRAININGS_LIST_START:
      return trainingsListStart(state);
    case actionTypes.TRAININGS_LIST_SUCCESS:
      return trainingsListSuccess(state, action);
    case actionTypes.TRAININGS_LIST_FAIL:
      return trainingsListFail(state, action);
    default:
      return state;
  }
};

export default reducer;
