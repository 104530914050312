export const LOG_IN_START = 'LOG_IN_START';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'LOG_IN_FAIL';
export const LOG_IN_SET_REDIRECT_PATH = 'LOG_IN_SET_REDIRECT_PATH';
export const AUTH_DELETE_TOKEN = 'AUTH_DELETE_TOKEN';

export const LOG_OUT_START = 'LOG_OUT_START';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';

export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export const USER_DATA_START = 'USER_DATA_START';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const USER_DATA_FAIL = 'USER_DATA_FAIL';

export const TRAININGS_LIST_START = 'TRAININGS_LIST_START';
export const TRAININGS_LIST_SUCCESS = 'TRAININGS_LIST_SUCCESS';
export const TRAININGS_LIST_FAIL = 'TRAININGS_LIST_FAIL';

export const TRAININGS_REGISTER_START = 'TRAININGS_REGISTER_START';
export const TRAININGS_REGISTER_SUCCESS = 'TRAININGS_REGISTER_SUCCESS';
export const TRAININGS_REGISTER_FAIL = 'TRAININGS_REGISTER_FAIL';

export const GENERATE_BOT_TOKEN_START = 'GENERATE_BOT_TOKEN_START';
export const GENERATE_BOT_TOKEN_SUCCESS = 'GENERATE_BOT_TOKEN_SUCCESS';
export const GENERATE_BOT_TOKEN_FAIL = 'GENERATE_BOT_TOKEN_FAIL';

export const LEADERBOARD_TIMES_START = 'LEADERBOARD_TIMES_START';
export const LEADERBOARD_TIMES_SUCCESS = 'LEADERBOARD_TIMES_SUCCESS';
export const LEADERBOARD_TIMES_FAIL = 'LEADERBOARD_TIMES_FAIL';

export const LEADERBOARD_SCORES_START = 'LEADERBOARD_SCORES_START';
export const LEADERBOARD_SCORES_SUCCESS = 'LEADERBOARD_SCORES_SUCCESS';
export const LEADERBOARD_SCORES_FAIL = 'LEADERBOARD_SCORES_FAIL';
