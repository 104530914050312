import axios from './axios_web';

import { logOut } from './logOut';
import * as actionTypes from './actionTypes';

export const signUpStart = () => ({
  type: actionTypes.SIGN_UP_START,
});

export const signUpSuccess = (data) => ({
  type: actionTypes.SIGN_UP_SUCCESS,
  isOpen: data.open,
  requiresCode: data.code,
  email: data.email,
  token: data.token,
  error: data.statusCode === 400 ? data.message : null,
});

export const signUpFail = (error) => ({
  type: actionTypes.SIGN_UP_FAIL,
  error,
});

export const signUpBegin = () => (
  (dispatch) => {
    dispatch(signUpStart());
    const url = '/users/signup';
    axios.get(url)
      .then((response) => {
        dispatch(signUpSuccess(response.data));
      })
      .catch((err) => {
        let error = err.message;
        if (err.response && err.response.data) {
          error = err.response.data.error;
        }
        dispatch(signUpFail(error));
      });
  }
);

export const tokenConfirm = (token) => (
  (dispatch) => {
    dispatch(logOut());
    dispatch(signUpStart());
    const authData = {
      token,
    };
    const url = '/users/token';
    axios.post(url, authData)
      .then((response) => {
        if (response.status === 204) {
          response.data = { open: true };
          dispatch(signUpSuccess(response.data));
        } else {
          dispatch(signUpFail(response.data.detail));
        }
      })
      .catch((err) => {
        dispatch(signUpFail(err.response.data.error));
      });
  }
);
